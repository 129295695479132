<template>
  <div v-if="canAccess('contratos_read')">
    <BlockUI :blocked="loading" :fullScreen="true" style="position:absolute;top:50%;left:50%;">
      <i v-if="loading" class="pi pi-spin pi-spinner" style="font-size: 3em;"></i>
    </BlockUI>
    <holder :holder="holderData" :edit="false" v-if="!loading"></holder>
    <div class="p-grid">
      <Fieldset legend="Matrículas" :toggleable="true">
        <ProgressBar mode="indeterminate" v-if="loading"/>
        <DataTable :value="enrollments" :filters="filters" scrollable>
          <template #header>
            <Toolbar class="p-col-12 p-mb-1">
              <template #left>
                <h5 class="p-mr-2">
                  Records de producción
                </h5>
              </template>
              <template #right>
                <Button label="Editar titular" icon="pi pi-user" class="p-button-info p-mr-2"
                        @click="editHolder = true, currentItem = null"/>
                <Button label="Nueva matrícula" icon="pi pi-plus" class="p-button-success p-mr-2"
                        @click="editDialog = true, currentItem = null"/>
              </template>
            </Toolbar>
          </template>
          <Column v-for="col of columns" :field="col.field" :header="col.header" :key="col.field">
            <template #body="slotProps"
                      v-if="col.field === 'cost' || col.field === 'balance' || col.field==='value_transition'">
              {{ $filters.moneyFormat(slotProps.data[col.field]) }}
            </template>
          </Column>

          <Column header="Opciones" headerStyle="width: 200px">
            <template #body="slotProps">
              <Button v-tooltip="'Editar matrícula'" icon="pi pi-pencil"
                      class="p-button-rounded p-button-secondary  p-mr-2" @click="edit(slotProps.data)"/>
              <Button v-tooltip="'Agregar/editar estudiante'" icon="pi pi-user-plus"
                      class="p-button-rounded p-button-info  p-mr-2" @click="editStudent(slotProps.data)"/>
              <Button v-tooltip="'Realizar pagos'" icon="pi pi-money-bill" @click="doPayments(slotProps.data)"
                      class="p-button-rounded p-button-help p-mr-2"
                      v-if="(slotProps.data.balance-slotProps.data.value_transition)> 0 && slotProps.data.employee?.name!='ASESOR IMPORTACION'"/>
              <Button :disabled="!canMigrate(slotProps.data)" v-tooltip="'Revisar y enviar contrato'"
                      icon="pi pi-external-link" @click="doContract(slotProps.data)"
                      class="p-button-rounded p-button-success p-mr-2" v-else-if="isContractAvailable"/>
              <Button v-if="isAdmin" icon="pi pi-trash" class="p-button-rounded p-button-warning"
                      @click="confirmDelete(slotProps.data)"/>
            </template>
          </Column>
        </DataTable>
      </Fieldset>
    </div>
    <Dialog v-model:visible="editDialog" :style="{width: '800px'}" header="Datos de matrícula"
            :modal="true"
            class="p-fluid">
      <enrollment ref="enrollForm" :holder="holderData" :enroll="currentItem" v-if="editDialog"></enrollment>
      <template #footer>
        <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="editDialog = false"/>
        <Button label="Guardar" icon="pi pi-check" class="p-button-text" @click="saveEnroll"/>
      </template>
    </Dialog>
    <Dialog v-model:visible="deleteDialog" :style="{width: '450px'}" header="Confirmar" :modal="true">
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem"/>
        <span v-if="currentItem">Seguro de eliminar registro?</span>
      </div>
      <template #footer>
        <Button label="No" icon="pi pi-times" class="p-button-text"
                @click="deleteDialog = false"/>
        <Button label="Si" icon="pi pi-check" class="p-button-text" @click="deleteRow"/>
      </template>
    </Dialog>
    <Sidebar v-model:visible="paymentsSidebar" :baseZIndex="1000" position="right" :style="'width:60%'" @hide="getData">
      <h3>Pagos de matícula</h3>
      <payments ref="paymentList" v-if="currentItem" :enroll="currentItem" :edit="true"></payments>
    </Sidebar>
    <Sidebar v-model:visible="studentSidebar" :baseZIndex="1000" position="right" :style="'width:60%'">
      <h3>Estudiantes</h3>
      <students ref="studentList" v-if="currentItem" :enroll="currentItem" :edit="true"></students>
    </Sidebar>

    <Sidebar v-model:visible="contractSidebar" :baseZIndex="1000" position="right" :style="'width:60%'">
      <h3>Contrato</h3>
      <contract ref="studentList" v-if="currentItem" :enroll="currentItem" :edit="true"
                @closeSidebar="contractSidebar = false"></contract>
    </Sidebar>
    <Sidebar v-model:visible="editHolder" :baseZIndex="1000" position="right" :style="'width:70%'" class="custombar">
      <h3>{{ currentItem ? 'Editar titular' : 'Nuevo titular' }}</h3>
      <holder ref="holderForm" :holder="holderData" :edit="true" :typeEmployees=typeEmployees :typeHousing=typeHousing
              @cancel="editHolder = false" @ok="getData"></holder>
      <div class="p-grid p-jc-end">
        <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="editHolder = false"/>
        <Button label="Guardar" icon="pi pi-check" class="p-button-text" @click="saveHolder"/>
      </div>
    </Sidebar>
  </div>
  <div v-else>
    <h5 class="card-header text-center"><b>ATENCIÓN!!</b></h5>
    <div class="card-body">
      <p class="card-title">El acceso a esta opción no se encuentra autorizado</p>
      <Button label="Regresar" class="p-button-danger p-mr-2"
              @click="$router.push('/admin')"/>
    </div>
  </div>
</template>
<script>
import bouncer from "../../../../helpers/bouncer";
import holderService from '../../service/holder.service'
import service from '../../service/enrollment.service'
import {defineAsyncComponent} from "vue";
import {mapState} from "vuex";
import catalogService from "../../../core/service/catalog.service";

export default {
  mixins: [bouncer],
  components: {
    holder: defineAsyncComponent(() => import('../holders/Holder')),
    enrollment: defineAsyncComponent(() => import('./Enrollment')),
    students: defineAsyncComponent(() => import('../students/StudentList')),
    contract: defineAsyncComponent(() => import('../contracts/Contract')),
    payments: defineAsyncComponent(() => import('../payments/PaymentList'))

  },
  data() {
    return {
      loading: false,
      paymentsSidebar: false,
      editHolder: false,
      studentSidebar: false,
      contractSidebar: false,
      deleteDialog: false,
      editDialog: false,
      studentDialog: false,
      holderData: {},
      enrollments: [],
      columns: null,
      filters: {},
      currentItem: null,
      typeEmployees: [],
      typeHousing: [],
    }
  },

  mounted() {
    if (this.canAccess('contratos_read')) {
      this.columns = [
        {field: 'sequential', header: 'N. Matrícula'},
        {field: 'registration', header: 'Fecha de registro'},
        {field: 'plan.name', header: 'Plan'},
        {field: 'cost', header: 'Valor'},
        {field: 'balance', header: 'Saldo'},
        {field: 'status', header: 'Estado'},
        {field: 'value_transition', header: 'Valor en transición'},
        {field: 'employee.name', header: 'Asesor'},
        {field: 'subsidiary.name', header: 'Filial'},
        // {field: 'registration_origin.description', header: 'Origen'}
      ];
      if (this.$route.params.id) {
        this.loading = true;
        const id = this.$route.params.id;
        holderService.get(id).then(x => {
          this.holderData = x.data;
          if (!this.country) {
            if (this.holderData.country) {
              this.$store.dispatch('enroll/setCountry', this.holderData.country);
            } else {
              this.$router.push('/verification/holders')
            }
          }
          catalogService.get('holders/types/employee').then(x => {
            this.typeEmployees = [];
            Object.keys(x.data).forEach(k => {
              this.typeEmployees.push({value: k, label: x.data[k]});
            });
            this.typeEmployees = Object.values(this.typeEmployees)
          });
          catalogService.get('holders/types/housing').then(x => {
            this.typeHousing = [];
            Object.keys(x.data).forEach(k => {
              this.typeHousing.push({value: k, label: x.data[k]});
            });
          });
          this.getData();
          this.loading = false
        });
      }
    }

  },
  methods: {
    canMigrate(data) {
      if (data.employee?.name == 'ASESOR IMPORTACION') {
        return true
      }
      return (data.payments.reduce((accumulator, current) => {
        return (current.status == 'Aprobado') ? accumulator + current.value_paid : accumulator + 0;
      }, 0) + data.value_transition) >= data.cost
    },

    saveHolder() {
      this.$refs.holderForm.$refs.holderForm.save().then(() => {
        this.getData()
      })
    },
    editStudent(ev) {
      if (ev.balance - ev.value_transition <= 0) {
        this.currentItem = ev;
        this.$nextTick(() => {
          this.studentSidebar = true;
        })
      } else {
        if (ev.balance > 0 && ev.employee?.name != 'ASESOR IMPORTACION') {
          this.$toast.add({
            severity: 'warn',
            summary: 'Pagos requeridos',
            detail: 'El valor total de la matrícula no ha sido registrado',
            life: this.$utils.toastLifeTime()
          });
        } else {
          this.currentItem = ev;
          this.$nextTick(() => {
            this.studentSidebar = true;
          })
        }
      }
    },
    deleteRow() {
      service.delete(this.currentItem.id).then(() => {
        this.$toast.add({
          severity: 'success',
          summary: 'Eliminado correctamente',
          life: this.$utils.toastLifeTime()
        });
      }).finally(() => {
        this.getData();
      })
    },
    edit(val) {
      this.currentItem = val;
      this.$nextTick(() => {
        this.editDialog = true;
      })

    },
    doPayments(val) {
      this.$store.dispatch('enroll/setBalance', val.balance);
      this.currentItem = val;
      this.$nextTick(() => {
        this.paymentsSidebar = true
      })
    },
    isContractAvailable() {
      return this.currentItem ? (this.currentItem.students.length > 0 || (this.currentItem.is_holder_student === 'y' || this.currentItem.is_holder_student === true)) : false;
    },
    doContract(val) {
      this.currentItem = val;
      if (this.isContractAvailable()) {
        this.$nextTick(() => {
          this.contractSidebar = true
        })
      } else {
        this.$toast.add({
          severity: 'warn',
          summary: 'Datos incompletos',
          detail: 'Verifique los beneficiarios.',
          life: this.$utils.toastLifeTime()
        });
      }

    },
    confirmDelete(val) {
      this.currentItem = val;
      this.deleteDialog = true;
    },
    saveEnroll() {
      const ev = this.$refs.enrollForm.$refs.enrollForm.save();
      if (!ev) {
        return
      }
      ev.then(() => {
        this.getData()
      }).catch(() => {
        this.loading = false
      })
    },
    getData() {
      this.loading = true;
      this.editDialog = false;
      this.deleteDialog = false;
      this.studentDialog = false;
      this.currentItem = null;
      service.getByHolder(this.holderData.id).then(x => {
        this.enrollments = x.data;
      }).catch(() => {
        this.enrollments = [];
      }).finally(() => {
        this.loading = false;
      })
    },
  },
  computed: {
    ...mapState('enroll', ['country']),
    ...mapState('auth', ['isAdmin']),
  }
}
</script>
<style lang="scss" scoped>
.p-progressbar {
  height: 1px;
}

.p-toolbar {
  margin-top: 10px;
  background-color: white;
}

.p-toolbar-group-right {
  width: 100%;
}


</style>
