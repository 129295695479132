import httpService from '../../../helpers/http.service'

export default {
    getAllRaise(params) {
        return httpService.get(`holders-raise`, {params})
    },
    getAll(params) {
        return httpService.get(`holders`, {params})
    },
    synchronize(params) {
        return httpService.post(`holders/sync`,params)
    },
    get(id, params = {}) {
        return httpService.get(`holders/${id}`, {params})

    },
    save(params, id = null) {
        if(id) {
            return httpService.put(`holders${id ? '/' + id : ''}`, params);
        }
        return httpService.post(`holders`, params);
    }
}
